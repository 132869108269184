import { Outlet, NavLink, Link } from "react-router-dom";

import images from "../../assets/images.jpg";

import styles from "./Layout.module.css";

const Layout = () => {
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>

                <div className={styles.headerNavLeftMargin}>
                                <a href="https://www.analog.com/en/index.html" target={"_blank"} title="ADI web link">
                                    <img
                                        src={images}
                                        alt="ADI logo"
                                        aria-label="Link to ADI website"
                                        width="40px"
                                        height="20px"
                                        className={styles.githubLogo}
                                    />
                                </a>
                            </div>

                    <Link to="/" className={styles.headerTitleContainer}>
                        <h3 className={styles.headerTitle}>ADI Search Co-Pilot MVP</h3>
                    </Link>
                    <nav>
                        <ul className={styles.headerNavList}>
                            <li>
                                <NavLink to="/" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    Chat
                                </NavLink>
                            </li>
                            {/* <li className={styles.headerNavLeftMargin}>
                                <NavLink to="/qa" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    Ask a question
                                </NavLink>
                            </li> */}
                            {/* <li className={styles.headerNavLeftMargin}>
                                <a href="https://www.analog.com/en/index.html" target={"_blank"} title="ADI web link">
                                    <img
                                        src={images}
                                        alt="ADI logo"
                                        aria-label="Link to ADI website"
                                        width="30px"
                                        height="20px"
                                        className={styles.githubLogo}
                                    />
                                </a>
                            </li> */}
                        </ul>
                    </nav>
                    {/* <h4 className={styles.headerRightText}>NOTE: Please record the feedback for every response</h4> */}
                </div>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;
