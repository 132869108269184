import React, { useEffect } from "react";

export default function Arraylist(props: any) {
  const [hide, isHide] = React.useState(true);
 


  return (
    <div style={{ display: props.block ? "block" : "none" }}>
      {hide &&
        props?.value?.map((item: any) => (
          <ul style={{border:'1px solid black'}}>
            {Object.entries(item).map(([key, value]: any) => (
              <li key={key}>
                {key}: {typeof value == "object" ? "" : value}
              </li>
            ))}
          </ul>
        ))}
      <div>
      </div>
    </div>
  );
}
