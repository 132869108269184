import React from "react";

export default function ObjectList(props:any) {


  return (
    <div>
      {props.value && Object.entries(props.value).map(([key, value]:any) => (
        <>
          <div>
            {key} : {typeof value=="object"? "" : value}
          </div>
        </>
      ))}
    </div>
  );
}
