import { useEffect, useState } from "react";
import paginationStyles from "./CitationContent.module.css";
import Arraylist from "../../api/Arraylist";
import ObjectList from "../../api/ObjectList";

import React from "react";
import { CursorHover16Filled } from "@fluentui/react-icons";
interface Props {
  citationContent: string;

}

let items: any = {};
//let array: any = [];
// function printRecursive(obj: any) {
//   for (const key in obj) {
//     if (typeof obj[key] === "object") {
//       // If the value is an object or array, recursively call the function

//       printRecursive(obj[key]);
//     } else {
//       // Print the key-value pair
//       array.push({ [key]: obj[key] });
//       // console.log(`${key}: ${obj[key]}`);
//     }
//   }
// }
export const CitationContent = ({ citationContent }: Props) => {
  const [row, setRow] = React.useState([] as any);
  // const [columns, setColumns] = React.useState(array);
  const [block, setBlock] = React.useState(false);
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => { setIsReadMore(!isReadMore) };
  
  async function getCitationData(): Promise<any> {
    const response = await fetch(citationContent);
    const body: any = await response.json();
    // console.log("Body: ", body);
    setRow([body]);
    return body;

  }

  const [state, setState] = useState([]);

  useEffect(() => {
    getCitationData();
    // getCitationData().then((data: any) => {
    //  // printRecursive(data);
    //   console.log("Data: ", array[0].Product);
    //   setState(array);
    // }
    // )
  }, [])


  //items = getCitationData()
  // console.log("Item:", getCitationData());
  // console.log("citationContent: ", citationContent);
  return (

    <div>
    <div className={paginationStyles.tableResponsive}>
      {/*----------------------------------------------------------------------------------*
       *                              Table Starts                                 *
       *----------------------------------------------------------------------------------*/}
      <div className={`${paginationStyles.table}`}>
        {row.map((item: any, index: any) => (
          <div key={index}>
            {item &&
              Object.entries(item).map(([key, value]: any) => (
                <div key={key}>
                  <strong style={{color: Array.isArray(value)?"red":"black", cursor: Array.isArray(value)?"pointer":""}}
                    onClick={() =>
                      typeof value === "object" && setBlock(!block)
                    }
                  >
                    {key}{" "}
                  </strong>
                  :{" "}
                  {typeof value === "object" ? (
                    Array.isArray(value) ? (
                      <div >
                        <Arraylist {...{ value,block }} />{" "}
                      </div>
                    ) : (
                      <ObjectList {...{ value }} />
                    )
                  ) : (
                    value
                  )}
                </div>
              ))}
          </div>
        ))}
      </div>

      {/*----------------------------------------------------------------------------------*
       *                              Table Ends                                 *
       *----------------------------------------------------------------------------------*/}
    </div>
  </div>



    //  <div> <h1>Hello</h1></div>
    // <iframe title="Citation" src={citationContent} width="100%" height="80%"/>

    // <div className="App mt-5">

    //   <table border={1} >
    //     {state.map((element, index) => {
    //       return (
    //         <tr >
    //           <td className={`${styles.cell}`}>
    //           <span className={`${styles.bold}`}>{Object.keys(element)} :- </span> {element[Object.keys(element) as any]}
    //             </td>
    //         </tr>
    //       );
    //     })}

    //   </table>
    // </div>
  );

};
