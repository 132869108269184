import * as React from 'react';
import { useMemo } from "react";
import { Stack, IconButton } from "@fluentui/react";
import DOMPurify from "dompurify";
import { Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles } from '@fluentui/react/lib/Dropdown';


import styles from "./Answer.module.css";

import { AskResponse, getCitationFilePath } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";

interface Props {
    answer: AskResponse;
    isSelected?: boolean;
    onCitationClicked: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    onFeedBackClicked: any;
    showFollowupQuestions?: boolean;
}

const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };

const dropdownControlledExampleOptions = [
    { key: 'optionsHeader', text: 'Your Response', itemType: DropdownMenuItemType.Header },
    { key: 'Correct', text: 'Correct' },
    { key: 'Partially Correct', text: 'Partially Correct' },
    { key: 'Incorrect', text: 'Incorrect' },
    { key: 'Missing Source', text: 'Missing Source' },
    { key: 'Unsure', text: 'Unsure' }
];

// 

export const Answer = ({
    answer,
    isSelected,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    onFeedBackClicked,
    showFollowupQuestions
}: Props) => {
    const parsedAnswer = useMemo(() => parseAnswerToHtml(answer.answer, onCitationClicked), [answer]);

    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
    const [selectedItem, setSelectedItem] = React.useState<IDropdownOption>();
    console.log(selectedItem?.key);

    // const state = {
    //     selectedValue: "noPaymentMethod"
    // };

    // const [selectedValue, setState] = React.useState<string>();
    // // const handleSelectValue = (event:any) => {
    // //     setState(event.target.value);
    // //   };
    // const handleSelectValue = (event:any) => {
    //     setState(event.target.value);
    //     console.log(event.target.value);
    //     };
    //const handleSelectValue = ( item: IDropdownOption) => {
    //setSelectedItem(item);
    //let question = makeApiRequest.;
    // console.log(lastQuestionRef.current);
    // console.log(answers[0][1].answer);
    // console.log(item.text);
    // console.log(question);
    //};
    // const [value, setValue] = React.useState("Correct");
    // const handleChange = (e: any) => {
    //   setValue(e.value);
    // };





    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <AnswerIcon />
                    <div>
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.thoughts}
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.data_points.length}
                        />

                        {/* <form onSubmit={onFeedBackClicked()}>
                            <div> */}
                        <Dropdown
                            label="FAE Feedback: Was this answer correct?"
                            selectedKey={selectedItem ? selectedItem.key : undefined}
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange= {onFeedBackClicked(selectedItem?.key)}
                            placeholder="Select an option"
                            options={dropdownControlledExampleOptions}
                            styles={dropdownStyles}
                            //disabled={!selectedItem?.key}
                        />
                            {/* </div>

                            <button
                                type="submit"
                                className="btn btn-primary"
                                //disabled={state.selectedValue === "noPaymentMethod"}
                            >
                                Submit
                            </button>
                        </form> */}

                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
            </Stack.Item>

            {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>Citations:</span>
                        {parsedAnswer.citations.map((x, i) => {
                            const path = getCitationFilePath(x);
                            return (
                                <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path)}>
                                    {`${++i}. ${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

            {!!parsedAnswer.followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                        {parsedAnswer.followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
        </Stack>
    );
};

